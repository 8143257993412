import { Avatar, ListItemAvatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getUserRoleDescription } from '../../utils';
import * as S from './styles';
import { NavigationProfileProps } from './type';

const NavigationProfile = ({ email, role }: NavigationProfileProps) => {
  const { t } = useTranslation();
  const roleDescription = getUserRoleDescription(role, t);

  return (
    <S.ListItem>
      <ListItemAvatar>
        <Avatar src="/icons/profile-placeholder.svg" />
      </ListItemAvatar>
      <S.ListItemText
        primary={email}
        secondary={roleDescription}
        primaryTypographyProps={{
          fontSize: 14,
          noWrap: true,
        }}
        secondaryTypographyProps={{
          fontSize: 12,
          noWrap: true,
        }}
      />
    </S.ListItem>
  );
};

export default NavigationProfile;
