import useTranslation from 'shared/hooks/useTranslation';
import { logout } from 'store/auth/auth.actions';
import { useAppDispatch } from 'app/hooks';
import { SvgNavLogout } from 'svg';
import * as S from './Logout.styles';
import { TEST_IDS } from '../../constants';

const Logout = () => {
  const { logoutButton } = TEST_IDS;
  const dispatch = useAppDispatch();
  const onLogoutClick = () => dispatch(logout());

  const [translations] = useTranslation(['common.label.logout'] as const);

  return (
    <S.Container data-test={logoutButton} title={translations.commonLabelLogout} onClick={onLogoutClick}>
      <SvgNavLogout />
    </S.Container>
  );
};

export default Logout;
