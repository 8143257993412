import { useState } from 'react';
import LoginFormAuth from './organisms/LoginFormAuth';
import LoginFormResetPassword from './organisms/LoginFormResetPassword';
import * as S from './styles';
import { AuthStep } from './types';

const LoginPage = () => {
  const [stepAuth, setStepAuth] = useState<AuthStep>(AuthStep.AUTH_STEP_LOGIN);

  switch (stepAuth) {
    case AuthStep.AUTH_STEP_LOGIN: {
      return (
        <S.Wrapper>
          <S.ImgWrapper>
            <S.Img src={`${process.env.PUBLIC_URL}/logoblack192.svg`} />
          </S.ImgWrapper>
          <LoginFormAuth onForgotPasswordClick={() => setStepAuth(AuthStep.AUTH_STEP_RESET_PASSWORD)} />
        </S.Wrapper>
      );
    }
    case AuthStep.AUTH_STEP_RESET_PASSWORD: {
      return (
        <S.Wrapper>
          <S.ImgWrapper>
            <S.Img src={`${process.env.PUBLIC_URL}/logoblack192.svg`} />
          </S.ImgWrapper>
          <LoginFormResetPassword onBackToLoginClick={() => setStepAuth(AuthStep.AUTH_STEP_LOGIN)} />
        </S.Wrapper>
      );
    }
    default: {
      return null;
    }
  }
};

export default LoginPage;
