import { TFunction } from 'react-i18next';
import { UserRole } from 'store/users/users.type';
import {
  SvgNavAppointments,
  SvgNavLeads,
  SvgNavPayrollReports,
  SvgNavSchedule,
  SvgNavUsers,
  SvgUserCircle,
} from '../../svg';
import { NavigationMenuItem } from './types';

const USER_ROLE_DESCRIPTION_MAP: {
  [key in UserRole]: string;
} = {
  [UserRole.ADMIN]: 'user.form.select.role.admin',
  [UserRole.WORKER]: 'user.form.select.role.worker',
  [UserRole.MANAGER]: 'user.form.select.role.manager',
};

export function getUserRoleDescription(userRole: UserRole | undefined, t: TFunction<'translation'>) {
  return t(USER_ROLE_DESCRIPTION_MAP[userRole ?? UserRole.WORKER]);
}

export function getAdminNavigationItems(t: TFunction<'translation'>): NavigationMenuItem[] {
  return [
    {
      Icon: SvgNavSchedule,
      id: 'SCHEDULE',
      link: '/schedule',
      primary: t('shared.inputs.navigation.schedule'),
      type: 'link',
    },
    {
      Icon: SvgNavPayrollReports,
      id: 'PAYROLL_REPORTS',
      link: '/payroll-reports',
      primary: t('shared.inputs.navigation.payroll'),
      type: 'link',
    },
    {
      Icon: SvgNavLeads,
      id: 'Leads',
      link: '/leads',
      primary: t('shared.inputs.navigation.leads'),
      type: 'link',
    },
    {
      Icon: SvgNavAppointments,
      id: 'APPOINTMENTS',
      primary: t('shared.inputs.navigation.appointments'),
      link: '/appointments-menu',
      routes: [
        {
          id: 'INDUSTRIES',
          link: '/industries',
          primary: t('shared.inputs.navigation.industries'),
          type: 'link',
        },
        {
          id: 'GROUPS',
          link: '/appointment-groups',
          primary: t('shared.inputs.navigation.appointment.groups'),
          type: 'link',
        },
        {
          id: 'APPOINTMENTS',
          link: '/appointments',
          primary: t('shared.inputs.navigation.appointments'),
          type: 'link',
        },
        {
          id: 'ADDONS',
          link: '/addons',
          primary: t('shared.inputs.navigation.addons'),
          type: 'link',
        },
      ],
      type: 'button',
    },
    {
      Icon: SvgNavUsers,
      id: 'USERS',
      link: '/users',
      primary: t('shared.inputs.navigation.users'),
      type: 'link',
    },
    {
      Icon: SvgUserCircle,
      id: 'CLIENTS',
      link: '/clients',
      primary: t('shared.inputs.navigation.clients'),
      type: 'link',
    },
  ];
}

export function getManagerNavigationItems(t: TFunction<'translation'>): NavigationMenuItem[] {
  return [
    {
      Icon: SvgNavSchedule,
      id: 'SCHEDULE',
      link: '/schedule',
      primary: t('shared.inputs.navigation.schedule'),
      type: 'link',
    },
    {
      Icon: SvgNavUsers,
      id: 'MYAVAILABLITY',
      link: '/myavailability',
      primary: t('shared.inputs.navigation.myavailability'),
      type: 'link',
    },
    {
      Icon: SvgNavPayrollReports,
      id: 'PAYROLL_REPORTS',
      link: '/payroll-reports',
      primary: t('shared.inputs.navigation.payroll'),
      type: 'link',
    },
    {
      Icon: SvgNavLeads,
      id: 'Leads',
      link: '/leads',
      primary: t('shared.inputs.navigation.leads'),
      type: 'link',
    },
    {
      Icon: SvgUserCircle,
      id: 'CLIENTS',
      link: '/clients',
      primary: t('shared.inputs.navigation.clients'),
      type: 'link',
    },
  ];
}
