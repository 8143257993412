import styled from '@emotion/styled';
import { Dialog as MuiDialog, DialogContent as MuiDialogContent, DialogTitle as MuiDialogTitle } from '@mui/material';
import { SvgXSmall } from 'svg';

export const Dialog = styled(MuiDialog)``;
export const DialogContent = styled(MuiDialogContent)``;
export const DialogTitle = styled(MuiDialogTitle)`
  padding: 24px 24px 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
`;
export const DialogTitleInner = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e4;
  padding-bottom: 12px;
`;
export const CloseButton = styled(SvgXSmall)`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`;
