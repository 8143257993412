import { createSlice } from '@reduxjs/toolkit';
import TokenService from 'services/token.service';
import UserService from 'services/user.service';
import { logout, refreshTokens } from './auth.actions';
import { authApi } from './auth.api';
import { AuthState } from './auth.types';

const initialState: AuthState = {
  user: UserService.getLocalUser(),
  refreshToken: TokenService.getLocalRefreshToken(),
  accessToken: TokenService.getLocalAccessToken(),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state.user = null;
      state.refreshToken = null;
      state.accessToken = null;
    });
    builder.addCase(refreshTokens, (state, { payload }) => {
      state.user = payload.user;
      state.refreshToken = payload.refreshToken;
      state.accessToken = payload.accessToken;
    });
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.user = payload.user;
      UserService.updateLocalUser(payload.user);
    });
    builder.addMatcher(authApi.endpoints.login.matchRejected, (state) => {
      state.user = null;
      state.refreshToken = null;
      state.accessToken = null;
    });
  },
});

export default authSlice.reducer;
