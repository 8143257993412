import { createSlice } from '@reduxjs/toolkit';
import { logout } from 'store/auth/auth.actions';
import { appointmentGroupApi } from './api';
import { AppointmentGroupReducerState } from './type';

const initialState: AppointmentGroupReducerState = {
  appointmentGroups: [],
};

const appointmentGroupSlice = createSlice({
  name: 'appointmentGroupReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state.appointmentGroups = [];
    });
    builder.addMatcher(
      appointmentGroupApi.endpoints.getAppointmentGroups.matchFulfilled,
      (state, { payload: appointmentGroups }) => {
        state.appointmentGroups = appointmentGroups;
      },
    );
    builder.addMatcher(
      appointmentGroupApi.endpoints.getAppointmentGroupById.matchFulfilled,
      (state, { payload: appointmentGroup }) => {
        const appointmentGroupId = appointmentGroup.id;
        state.appointmentGroups = [
          ...state.appointmentGroups.filter((appointmentGroup) => appointmentGroup.id !== appointmentGroupId),
          appointmentGroup,
        ];
      },
    );
    builder.addMatcher(
      appointmentGroupApi.endpoints.createAppointmentGroup.matchFulfilled,
      (state, { payload: appointmentGroup }) => {
        state.appointmentGroups = [...state.appointmentGroups, appointmentGroup];
      },
    );
    builder.addMatcher(
      appointmentGroupApi.endpoints.updateAppointmentGroup.matchFulfilled,
      (state, { payload: appointmentGroup }) => {
        const appointmentGroupId = appointmentGroup.id;

        state.appointmentGroups = [
          ...state.appointmentGroups.filter((appointmentGroup) => appointmentGroup.id !== appointmentGroupId),
          appointmentGroup,
        ];
      },
    );
    builder.addMatcher(
      appointmentGroupApi.endpoints.duplicateAppointmentGroup.matchFulfilled,
      (state, { payload: appointmentGroup }) => {
        state.appointmentGroups = [...state.appointmentGroups, appointmentGroup];
      },
    );
    builder.addMatcher(
      appointmentGroupApi.endpoints.deleteAppointmentGroup.matchFulfilled,
      (state, { payload: appointmentGroupId }) => {
        state.appointmentGroups = [
          ...state.appointmentGroups.filter((appointmentGroup) => appointmentGroup.id !== appointmentGroupId),
        ];
      },
    );
  },
});

const { reducer } = appointmentGroupSlice;

export default reducer;
