import { createSlice } from '@reduxjs/toolkit';
import { logout } from 'store/auth/auth.actions';
import { addonsApi } from './api';
import { AddonReducerState } from './type';

const initialState: AddonReducerState = {
  addons: [],
};

const addonSlice = createSlice({
  name: 'addonReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state.addons = [];
    });
    builder.addMatcher(addonsApi.endpoints.getAddons.matchFulfilled, (state, { payload: addons }) => {
      state.addons = addons;
    });
    builder.addMatcher(addonsApi.endpoints.getAddonById.matchFulfilled, (state, { payload: addon }) => {
      const addonId = addon.id;
      state.addons = [...state.addons.filter((addon) => addon.id !== addonId), addon];
    });
    builder.addMatcher(addonsApi.endpoints.createAddon.matchFulfilled, (state, { payload: addon }) => {
      state.addons = [...state.addons, addon];
    });
    builder.addMatcher(addonsApi.endpoints.deleteAddon.matchFulfilled, (state, { payload: addonId }) => {
      state.addons = [...state.addons.filter((addon) => addon.id !== addonId)];
    });
  },
});

const { reducer } = addonSlice;

export default reducer;
