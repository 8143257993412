import TokenService from 'services/token.service';
import * as endpoints from 'store/apiUtils/endpoints';
import { api } from '../apiUtils/api';
import { AuthCredentials, LoginResponse, RequestResetPasswordBody } from './auth.types';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, AuthCredentials>({
      query: (credentials) => ({
        url: endpoints.login,
        method: 'POST',
        body: credentials,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        const {
          data: { refreshToken, accessToken },
        } = await queryFulfilled;
        TokenService.updateLocalAccessToken(accessToken);
        TokenService.updateLocalRefreshToken(refreshToken);
      },
      extraOptions: { skipRetry: true },
    }),
    sendResetPassword: builder.mutation<void, RequestResetPasswordBody>({
      query: (body) => ({
        url: `${endpoints.users}/send-reset-password`,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useLoginMutation, useSendResetPasswordMutation } = authApi;
