import styled from '@emotion/styled';
import { Form } from 'formik';
import PageTitle from 'UI/PageTitle';
import FieldsContainer from '../../atoms/FieldsContainer';

export const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 400px;
  padding: 0 16px;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const ResetPasswordFieldsContainer = styled(FieldsContainer)`
  gap: 16px;
`;

export const ResetPasswordTitle = styled(PageTitle)`
  font-size: 20px;
  margin-bottom: 16px;
`;
