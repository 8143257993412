import format from 'date-fns/format';
import { differenceInCalendarDays, differenceInCalendarMonths, differenceInCalendarWeeks } from 'date-fns';
import { CustomCalendarDayProps } from './CustomCalendarDay.types';
import * as S from './CustomCalendarDay.styles';
import { RepeatDateMode } from '../BigDatePicker/BigDatePicker.types';
import { NUMBER_OF_WEEKDAYS } from './CustomCalendarDay.constants';

const isFutureDate = (parsedDate: Date, chosenDate: Date | null, repeatUntilDate: Date | null, startDate?: Date) => {
  const parsedDateTimeStamp = +new Date(parsedDate);
  const currentDateTimeStamp = startDate ? +startDate.setHours(0, 0, 0, 0) : +new Date().setHours(0, 0, 0, 0);

  if (!chosenDate && !repeatUntilDate) return currentDateTimeStamp <= parsedDateTimeStamp;
  if (chosenDate && !repeatUntilDate) return new Date(chosenDate).setHours(0, 0, 0, 0) <= parsedDateTimeStamp;
  if (!chosenDate && repeatUntilDate) {
    return currentDateTimeStamp <= parsedDateTimeStamp && parsedDateTimeStamp <= +new Date(repeatUntilDate);
  }
  return (
    new Date(chosenDate!).setHours(0, 0, 0, 0) <= parsedDateTimeStamp &&
    parsedDateTimeStamp <= +new Date(repeatUntilDate!)
  );
};

const isSameWeekDay = (parsedDate: Date, chosenDate: Date) =>
  differenceInCalendarDays(parsedDate, chosenDate) % NUMBER_OF_WEEKDAYS === 0;

const isSameMonthDay = (parsedDate: Date, chosenDate: Date) => chosenDate.getDate() === parsedDate.getDate();

const isSelectedWeekDay = (customSelectedWeekdays: number[], parsedDate: Date) => {
  const dayNumber = new Date(parsedDate).getDay();
  return customSelectedWeekdays.includes(dayNumber);
};

const isSelectedDailyDate = (
  parsedDate: Date,
  chosenDate: Date | null,
  repeatUntilDate: Date | null,
  recurrenceNumber: number,
) =>
  recurrenceNumber
    ? isFutureDate(parsedDate, chosenDate, repeatUntilDate) &&
      differenceInCalendarDays(parsedDate, chosenDate!) < recurrenceNumber
    : isFutureDate(parsedDate, chosenDate, repeatUntilDate);

const isSelectedWeeklyDate = (
  parsedDate: Date,
  chosenDate: Date | null,
  repeatUntilDate: Date | null,
  recurrenceNumber: number,
) =>
  recurrenceNumber
    ? isFutureDate(parsedDate, chosenDate, repeatUntilDate) &&
      isSameWeekDay(parsedDate, chosenDate!) &&
      differenceInCalendarWeeks(parsedDate, chosenDate!) < recurrenceNumber
    : isFutureDate(parsedDate, chosenDate, repeatUntilDate) && isSameWeekDay(parsedDate, chosenDate!);

const isSelectedMonthlyDate = (
  parsedDate: Date,
  chosenDate: Date | null,
  repeatUntilDate: Date | null,
  recurrenceNumber: number,
) =>
  recurrenceNumber
    ? isFutureDate(parsedDate, chosenDate, repeatUntilDate) &&
      isSameMonthDay(parsedDate, chosenDate!) &&
      differenceInCalendarMonths(parsedDate, chosenDate!) < recurrenceNumber
    : isFutureDate(parsedDate, chosenDate, repeatUntilDate) && isSameMonthDay(parsedDate, chosenDate!);

const isSelectedCustomDate = (
  parsedDate: Date,
  chosenDate: Date | null,
  repeatUntilDate: Date | null,
  recurrenceNumber: number,
  customSelectedWeekdays: number[],
) => {
  if (!chosenDate) return false;

  const isFuture = isFutureDate(parsedDate, null, repeatUntilDate);
  const isSelected = isSelectedWeekDay(customSelectedWeekdays, parsedDate);
  const todayNumber = new Date().getDay();
  const parsedDateNumber = parsedDate.getDay();
  const chosenDateNumber = chosenDate.getDay();
  const presentDaysOnThisWeek = new Set(customSelectedWeekdays.filter((day) => day >= todayNumber));

  if (recurrenceNumber) {
    if (isFuture && isSelected) {
      if (presentDaysOnThisWeek.has(chosenDateNumber)) {
        if (presentDaysOnThisWeek.has(parsedDateNumber)) {
          return differenceInCalendarWeeks(parsedDate, chosenDate) < recurrenceNumber;
        }
        return differenceInCalendarWeeks(parsedDate, chosenDate) < recurrenceNumber + 1;
      }
      return differenceInCalendarWeeks(parsedDate, chosenDate) < recurrenceNumber;
    }
    return false;
  }
  return isFuture && isSelected;
};

const isDaySelected = (
  chosenDate: Date | null,
  parsedDate: Date,
  repeatMode: RepeatDateMode,
  customSelectedWeekdays: number[],
  recurrenceNumber: number,
  repeatUntilDate: Date | null,
): boolean => {
  if (repeatMode !== RepeatDateMode.Custom && !chosenDate) return false;
  switch (repeatMode) {
    case RepeatDateMode.Daily:
      return isSelectedDailyDate(parsedDate, chosenDate, repeatUntilDate, recurrenceNumber);
    case RepeatDateMode.Weekly:
      return isSelectedWeeklyDate(parsedDate, chosenDate, repeatUntilDate, recurrenceNumber);
    case RepeatDateMode.Monthly:
      return isSelectedMonthlyDate(parsedDate, chosenDate, repeatUntilDate, recurrenceNumber);
    case RepeatDateMode.Custom:
      return isSelectedCustomDate(parsedDate, chosenDate, repeatUntilDate, recurrenceNumber, customSelectedWeekdays);
    default:
      return chosenDate?.setHours(0, 0, 0, 0) === parsedDate.setHours(0, 0, 0, 0);
  }
};

const CustomCalendarDay = ({
  chosenDate,
  date,
  setDate,
  isReadonly,
  repeatMode,
  customSelectedWeekdays,
  recurrenceNumber,
  repeatUntilDate,
}: CustomCalendarDayProps) => {
  const day = format(date, 'd');
  const isSelected = isDaySelected(
    chosenDate,
    date,
    repeatMode,
    customSelectedWeekdays,
    recurrenceNumber,
    repeatUntilDate,
  );
  const isFuture = isFutureDate(date, null, repeatUntilDate);

  const handlePickDay = () => {
    if (isFuture && !isReadonly) setDate(date);
  };

  return (
    <S.DayWrapper isPast={!isFuture} onClick={handlePickDay}>
      <S.Selected isSelected={isSelected}>
        <S.SelectedIcon src="icons/tick-small.svg" />
      </S.Selected>
      <S.MonthDay isSelected={isSelected}>{day}</S.MonthDay>
    </S.DayWrapper>
  );
};

export default CustomCalendarDay;
