/* TODO download fonts */

const family = {
  poppins: 'Poppins',
  outfit: 'Outfit',
};

const size = {
  '40': '40px',
  '32': '32px',
  '26': '26px',
  '18': '18px',
  '20': '20px',
  '16': '16px',
  '14': '14px',
  '12': '12px',
  '10': '10px',
};

const weight = {
  '100': 100,
  '200': 200,
  '300': 300,
  '400': 400,
  '500': 500,
  '600': 600,
  '700': 700,
};

export default {
  family,
  size,
  weight,
};
