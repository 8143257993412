import { Renderable } from 'react-hot-toast';
import * as S from './Toast.styles';

type ToastProps = {
  icon?: Renderable;
  title?: string;
  subtitle?: Renderable;
};

const Toast = ({ icon, title, subtitle }: ToastProps) => (
  <>
    {icon}
    <S.Container>
      {title ? <S.Title>{title}</S.Title> : null}
      {subtitle ? <S.Subtitle>{subtitle}</S.Subtitle> : null}
    </S.Container>
  </>
);

export default Toast;
