import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

interface SectionTitleProps {
  big?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  gap: 16px;
  height: 44px;
  justify-content: center;
`;

export const SectionTitle = styled.div<SectionTitleProps>`
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  height: 100%;
  line-height: 1.5;
  padding: 8px 0;
  text-align: left;
  ${({ big }) =>
    big &&
    `
  font-size: 24px;
  line-height: 30px;
  `}
`;

export const IconWrapper = styled(IconButton)`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0;
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  gap: 16px;
  justify-content: flex-end;
  max-height: 100%;
  padding: 4px 0;
  vertical-align: middle;
  cursor: pointer;
`;
