import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItemText } from '@mui/material';
import { useState } from 'react';
import NavigationItemLink from '../NavigationItemLink';
import * as S from './styles';
import { NavigationItemButtonProps } from './type';

const NavigationItemButton = ({ Icon, primary, link, routes }: NavigationItemButtonProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <S.ListItemButton onClick={() => setIsExpanded(!isExpanded)}>
        <S.ListItemIcon>{Icon ? <Icon /> : null}</S.ListItemIcon>
        <ListItemText disableTypography primary={primary} />
        {isExpanded ? <ExpandLess color="inherit" /> : <ExpandMore color="inherit" />}
      </S.ListItemButton>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <List disablePadding>
          {routes.map((route) => (
            <NavigationItemLink
              id={route.id}
              key={route.id}
              link={`${link}${route.link}`}
              primary={route.primary}
              type="link"
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default NavigationItemButton;
