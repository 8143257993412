import { api } from 'store/apiUtils/api';
import * as endpoints from 'store/apiUtils/endpoints';
import { AppointmentGroup, CreateAppointmentGroupPayload, TimeRange, UpdateAppointmentGroupPayload } from './type';
import providesListTag from '../apiUtils/providesListTag';

export const appointmentGroupApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAppointmentGroups: builder.query<AppointmentGroup[], void>({
      query: () => endpoints.appointmentGroups,
      providesTags: (result) => providesListTag(result, 'AppointmentGroup'),
    }),
    getAppointmentGroupById: builder.query<AppointmentGroup, number>({
      query: (id) => `${endpoints.appointmentGroups}/${id}`,
      providesTags: (result) => [{ type: 'AppointmentGroup', id: result?.id }],
    }),
    createAppointmentGroup: builder.mutation<AppointmentGroup, CreateAppointmentGroupPayload>({
      query: (credentials) => ({
        url: `${endpoints.appointmentGroups}`,
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: [
        { type: 'AppointmentGroup', id: 'LIST' },
        { type: 'Industry', id: 'LIST' },
      ],
    }),
    // todo: update
    updateAppointmentGroup: builder.mutation<AppointmentGroup, UpdateAppointmentGroupPayload>({
      query: ({ id, ...rest }) => ({
        url: `${endpoints.appointmentGroups}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: (result) => [
        { type: 'AppointmentGroup', id: result?.id },
        { type: 'Industry', id: 'LIST' },
      ],
    }),
    duplicateAppointmentGroup: builder.mutation<AppointmentGroup, number>({
      query: (id) => ({
        url: `${endpoints.appointmentGroups}/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'AppointmentGroup', id: 'LIST' }],
    }),
    deleteAppointmentGroup: builder.mutation<number, number>({
      query: (id) => ({
        url: `${endpoints.appointmentGroups}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _, id) => [
        { type: 'AppointmentGroup', id },
        { type: 'Industry', id: 'LIST' },
      ],
    }),
    getTimeRanges: builder.query<TimeRange[], void>({
      query: () => `time-range`,
    }),
  }),
});

export const {
  useGetAppointmentGroupsQuery,
  useGetAppointmentGroupByIdQuery,
  useCreateAppointmentGroupMutation,
  useUpdateAppointmentGroupMutation,
  useDuplicateAppointmentGroupMutation,
  useDeleteAppointmentGroupMutation,
  useGetTimeRangesQuery,
} = appointmentGroupApi;
