import styled from '@emotion/styled';
import Select from '../Select';

export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const SelectTime = styled(Select)`
  width: 80px;
  flex: 0;

  & .MuiInputBase-root {
    height: 39px;
  }
`;

export const SelectPeriod = styled(SelectTime)`
  margin-left: 8px;
`;
