import { ListItem, ListItemText } from '@mui/material';
import NavigationLink from '../NavigationLink';
import * as S from './styles';
import { NavigationItemLinkProps } from './type';

const NavigationItemLink = ({ primary, link, Icon }: NavigationItemLinkProps) => (
  <ListItem component={NavigationLink} to={link}>
    <S.ListItemIcon>{Icon ? <Icon /> : null}</S.ListItemIcon>
    <ListItemText disableTypography primary={primary} />
  </ListItem>
);

export default NavigationItemLink;
