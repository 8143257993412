import { api } from 'store/apiUtils/api';
import * as endpoints from 'store/apiUtils/endpoints';
import providesListTag from 'store/apiUtils/providesListTag';
import { Lead } from 'store/leads/leads.types';
import {
  CreateUserBody,
  GetUsersParams,
  GetWorkersAvailabilityParams,
  GetWorkersScheduleParams,
  ResetPasswordBody,
  UpdateUserBody,
  User,
} from './users.type';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<User[], GetUsersParams | void>({
      query: (params) => (params && params.role ? `${endpoints.users}?role=${params.role}` : `${endpoints.users}`),
      providesTags: (result) => providesListTag(result, 'User'),
    }),
    getUsersByRole: builder.query<User[], string>({
      query: (role) => `${endpoints.users}?role=${role}`,
      providesTags: (result) => providesListTag(result, 'User'),
    }),
    getWorkersAvailability: builder.query<User[], GetWorkersAvailabilityParams>({
      query: ({ bookingId, endDate, startDate, industryId, recurrenceType, buildingId, recurrenceEndDate }) => ({
        url: `${endpoints.users}/worker`,
        method: 'GET',
        params: {
          bookingId,
          endDate,
          industryId,
          recurrenceType,
          recurrenceEndDate,
          startDate,
          buildingId,
        },
      }),
      providesTags: (result) => providesListTag(result, 'User'),
    }),
    getWorkersSchedule: builder.query<
      { assignee: User; bookings: (Lead & { duration: number })[] }[],
      GetWorkersScheduleParams
    >({
      query: ({ endDate, startDate, industryId, buildingId }) => ({
        url: `worker/schedule`,
        method: 'GET',
        params: {
          endDate,
          industryId,
          startDate,
          buildingId,
        },
      }),
    }),
    getUsersAvailability: builder.query<User, void>({
      query: () => `${endpoints.users}/own`,
    }),
    getManagers: builder.query<User[], { industryIds: number[]; buildingIds: number[] }>({
      query: ({ industryIds, buildingIds }) =>
        `${endpoints.users}/managers?industryIds=${industryIds.join(',')}&buildingIds=${buildingIds.join(',')}`,
    }),
    createUser: builder.mutation<User, CreateUserBody>({
      query: (body) => ({
        url: endpoints.users,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),

    updateUser: builder.mutation<User, UpdateUserBody>({
      query: ({ id, ...rest }) => ({
        url: `${endpoints.users}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: (result) => [{ type: 'User', id: result?.id }],
    }),
    deleteUser: builder.mutation<number, number>({
      query: (id) => ({
        url: `${endpoints.users}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _, id) => [{ type: 'User', id }],
    }),
    resetPassword: builder.mutation<void, ResetPasswordBody>({
      query: (body) => ({
        url: `${endpoints.users}/reset-password`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetManagersQuery,
  useGetUsersAvailabilityQuery,
  useGetUsersByRoleQuery,
  useGetUsersQuery,
  useGetWorkersAvailabilityQuery,
  useGetWorkersScheduleQuery,
  usePrefetch: useUsersPrefetch,
  useResetPasswordMutation,
  useUpdateUserMutation,
} = userApi;
