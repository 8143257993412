import { User, UserRole } from 'store/users/users.type';

export function isUserRoleAdmin(userRole: UserRole | null) {
  if (!userRole) {
    return false;
  }

  return userRole === UserRole.ADMIN;
}

export function isUserRoleWorker(userRole: UserRole | null) {
  if (!userRole) {
    return false;
  }

  return userRole === UserRole.WORKER;
}

export function isUserRoleManager(userRole: UserRole | null) {
  if (!userRole) {
    return false;
  }

  return userRole === UserRole.MANAGER;
}

export function isUserAdmin(user: User | null) {
  if (!user) {
    return false;
  }
  return isUserRoleAdmin(user.role);
}

export function isUserManager(user: User | null) {
  if (!user) {
    return false;
  }
  return isUserRoleManager(user.role);
}

export function isUserAdminOrManager(user: User | null) {
  if (!user) {
    return false;
  }

  return isUserAdmin(user) || isUserManager(user);
}

export function isUserWorker(user: User | null) {
  if (!user) {
    return false;
  }
  return isUserRoleWorker(user.role);
}
