import styled from '@emotion/styled';
import { ListItemButton as MuiListItemButton, ListItemIcon as MuiListItemIcon } from '@mui/material';
import { colors } from 'styles';

export const ListItemIcon = styled(MuiListItemIcon)`
  color: inherit;
  min-width: 36px;
`;

export const ListItemButton = styled(MuiListItemButton)`
  align-items: center;
  border-radius: 8px;
  color: ${colors.black};
  display: flex;
  height: 44px;
  overflow: hidden;
  padding: 8px 12px;
  text-decoration: none;
  user-select: none;
  width: 100%;
`;
