import { Form } from 'formik';
import styled from '@emotion/styled';
import { PageTitle } from 'UI';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
`;

export const ImgWrapper = styled.div`
  height: 80px;
  padding: 24px 12px;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
`;

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 400px;
  padding: 0 16px;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const ResetPasswordTitle = styled(PageTitle)`
  font-size: 20px;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  width: 100%;
`;
