import { Typography } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutesEnum } from 'routes';
import { registerFormikTextField } from 'shared/getters/getFormikProps';
import useTranslation from 'shared/hooks/useTranslation';
import { useResetPasswordMutation } from 'store/users/users.api';
import { Button } from 'UI';
import FieldsContainer from './atoms/FieldsContainer';
import ResetPasswordFormTextField from './atoms/ResetPasswordFormTextField';
import { resetPasswordValidationSchema, TEST_IDS, TOKEN_SEARCH_NAME } from './constants';
import * as S from './styles';
import { ResetPasswordFormFields } from './types';

const ResetPasswordPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const [isPasswordCreated, setIsPasswordCreated] = useState(false);
  const [translations] = useTranslation([
    'page.reset.password.new.password',
    'page.reset.password.new.password.repeat',
    'common.error.submit',
    'common.input.btn.submit',
    'page.login.back.to.login',
    'page.password.reset.success.subtitle',
    'page.password.reset.success.title',
    'page.password.reset.title',
  ] as const);

  const token = searchParams.get(TOKEN_SEARCH_NAME);
  const formik = useFormik<ResetPasswordFormFields>({
    initialValues: { password: '', confirmPassword: '' },
    validationSchema: resetPasswordValidationSchema,
    onSubmit: (values) => {
      resetPassword({ password: values.password, token: token || '' })
        .unwrap()
        .then(() => setIsPasswordCreated(true))
        .catch(() => toast.error(translations.commonErrorSubmit));
    },
  });

  const onGoToLoginClick = () => navigate(RoutesEnum.root);
  const isDisabledSubmit = !(formik.isValid && formik.dirty);

  if (isPasswordCreated) {
    return (
      <S.Wrapper>
        <S.ImgWrapper>
          <S.Img src={`${process.env.PUBLIC_URL}/logoblack192.svg`} />
        </S.ImgWrapper>
        <FormikProvider value={formik}>
          <S.Container>
            <S.ResetPasswordTitle title={translations.pagePasswordResetSuccessTitle} />
            <Typography fontSize={12}>{translations.pagePasswordResetSuccessSubtitle}</Typography>
            <Button
              data-test-id={TEST_IDS.backToLoginButton}
              loading={isLoading}
              variant="contained"
              color="primary"
              fullWidth
              onClick={onGoToLoginClick}
            >
              {translations.pageLoginBackToLogin}
            </Button>
          </S.Container>
        </FormikProvider>
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.ImgWrapper>
        <S.Img src={`${process.env.PUBLIC_URL}/logoblack192.svg`} />
      </S.ImgWrapper>
      <FormikProvider value={formik}>
        <S.Container>
          <S.ResetPasswordTitle title={translations.pagePasswordResetTitle} />
          <FieldsContainer>
            <ResetPasswordFormTextField
              data-test-id={TEST_IDS.passwordInput}
              type="password"
              {...registerFormikTextField('password', formik)}
              placeholder={translations.pageResetPasswordNewPassword}
            />
            <ResetPasswordFormTextField
              data-test-id={TEST_IDS.repeatPasswordInput}
              type="password"
              placeholder={translations.pageResetPasswordNewPasswordRepeat}
              {...registerFormikTextField('confirmPassword', formik)}
            />
          </FieldsContainer>
          <S.ButtonContainer>
            <Button
              disabled={isDisabledSubmit}
              data-test-id={TEST_IDS.sendPasswordButton}
              loading={isLoading}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              {translations.commonInputBtnSubmit}
            </Button>
          </S.ButtonContainer>
        </S.Container>
      </FormikProvider>
    </S.Wrapper>
  );
};

export default ResetPasswordPage;
