import { ToastType } from 'react-hot-toast/dist/core/types';
import { TFunction } from 'react-i18next';

export const toastStyles: React.CSSProperties = {
  alignItems: 'center',
  borderRadius: '8px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)',
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  height: '88px',
  minWidth: '200px',
  padding: '20px 24px',
};

export function getToastTitle(type: ToastType, t: TFunction<'translation'>) {
  if (type === 'success') {
    return t('toast.success.title');
  }

  if (type === 'error') {
    return t('toast.failure.title');
  }

  // Return `failure` as fallback.
  return t('toast.failure.title');
}
