import styled from '@emotion/styled';
import { colors } from 'styles';

export const Container = styled.span<{ error: boolean }>`
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  color: ${({ error }) => (error ? colors.error100 : colors.grey40)};
`;
