import styled from '@emotion/styled';

import { FormControl as MuiFormControl, FormHelperText, Select as MuiSelect } from '@mui/material';
import { CUSTOM_LABEL } from 'shared/constants';
import { colors } from '../../styles';
import { INPUT_PLACEHOLDER_CLASS } from '../InputPlaceholder/InputPlaceholder.types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const MarginOffTitle = styled.div`
  display: flex;
  flex-basis: 100%;
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
`;

export const FormControl = styled(MuiFormControl)`
  display: flex;
  flex-wrap: wrap;

  & .${CUSTOM_LABEL} {
    background: white;
    top: -7px;
  }

  & .${CUSTOM_LABEL}.MuiInputLabel-shrink {
    top: 0;
    padding-left: 4px;
    padding-right: 4px;
  }

  & .${INPUT_PLACEHOLDER_CLASS} {
    color: ${({ error }) => (error ? colors.error100 : colors.grey70)};
  }
`;

export const SelectHelperText = styled(FormHelperText)`
  margin: 4px 14px 0;
`;

export const Select = styled(MuiSelect)`
  width: 100%;
  height: 40px;

  & .MuiSelect-select {
    padding-bottom: 8px;
    padding-top: 8px;
    white-space: normal;
  }
`;

export const CircularProgressWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;
`;
