import * as S from './Dialog.styles';
import { DialogProps } from './Dialog.types';

const Dialog = (props: DialogProps): JSX.Element => {
  const { children, title, ...rest } = props;
  return (
    <S.Dialog {...rest}>
      {title && (
        <S.DialogTitle>
          <S.DialogTitleInner>{title}</S.DialogTitleInner>
        </S.DialogTitle>
      )}
      <S.CloseButton onClick={(e) => rest.onClose(e, 'backdropClick')} />
      <S.DialogContent>{children}</S.DialogContent>
    </S.Dialog>
  );
};

export default Dialog;
