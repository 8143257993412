import styled from '@emotion/styled';
import { ListItem as MuiListItem, ListItemText as MuiListItemText } from '@mui/material';
import { colors } from 'styles';

export const ListItem = styled(MuiListItem)`
  padding: 0px;
  margin-bottom: 8px;
`;

export const ListItemText = styled(MuiListItemText)`
  color: ${colors.black};
`;
