import styled from '@emotion/styled';
import { fonts } from 'styles';
import { Typography } from '@mui/material';

export const PageTitle = styled(Typography)`
  line-height: 21px;
  font-weight: 500;
  font-size: 24px;
  display: inline-block;
  font-family: ${fonts.family.outfit};
`;
