import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  gap: 24px;
  overflow-y: hidden;
  padding: 24px;

  @media (max-width: 768px) {
    gap: 16px;
    padding: 16px;
  }
`;
