import styled from '@emotion/styled';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { tooltipClasses } from '@mui/material';
import { colors } from '../../styles';

export const Container = styled(({ className, children, ...rest }: TooltipProps) => (
  <Tooltip {...rest} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.white,
    color: colors.black,
    fontWeight: 500,
    boxShadow: `0px 4px 12px 0px ${colors.grey20}`,
    borderRadius: '8px',
    whiteSpace: 'pre-wrap',
    padding: '12px',
    fontSize: 12,
  },
}));
