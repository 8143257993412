export const TEST_IDS = {
  logoutButton: 'nav-logout-button',
  toggleNavButton: 'nav-toggle-button',
  dashboardLink: 'nav-dashboard-link',
  scheduleLink: 'nav-schedule-link',
  usersLink: 'nav-users-link',
  myAvailabilityLink: 'nav-my-availability-link',
  leadsLink: 'nav-leads-link',
  calendarLink: 'nav-calendar-link',
  industriesLink: 'nav-industries-link',
  appointmentGroupsLink: 'nav-appointment-groups-link',
  appointmentsMenuDropdown: 'nav-appointments-menu-dropdown',
  leadsMenuDropdown: 'nav-leads-menu-dropdown',
  reportLink: 'nav-report-link',
};
