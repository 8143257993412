import { SectionTitleProps } from './SectionTitle.types';
import * as S from './SectionTitle.styles';

const SectionTitle = ({ big, buttonsLeft, buttonsRight, className, text }: SectionTitleProps): JSX.Element => (
  <S.Wrapper className={className}>
    <S.TitleWrapper>
      {buttonsLeft || null}
      <S.SectionTitle big={big} title={text}>
        {text}
      </S.SectionTitle>
    </S.TitleWrapper>
    {buttonsRight || null}
  </S.Wrapper>
);

export default SectionTitle;
