import { forwardRef } from 'react';
import { NavLinkProps } from 'react-router-dom';
import * as S from './styles';

type NavigationLinkProps = {
  children: React.ReactNode;
} & NavLinkProps;

const NavigationLink = forwardRef<HTMLAnchorElement, NavigationLinkProps>(({ to, children }, ref) => (
  <S.NavLink ref={ref} to={to}>
    {children}
  </S.NavLink>
));

export default NavigationLink;
