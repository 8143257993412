import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';
import { colors } from 'styles';

export const Container = styled(DataGrid)`
  .MuiDataGrid-overlay {
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .MuiDataGrid-columnHeaderTitle {
    padding: 0 0 0 10px;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0;
    color: ${colors.grey70};
  }
`;
