import { Middleware } from '@reduxjs/toolkit';
import TokenService from 'services/token.service';
import UserService from 'services/user.service';
import { logout } from 'store/auth/auth.actions';

export const logoutMiddleware: Middleware<
  {},
  unknown
  // eslint-disable-next-line unicorn/consistent-function-scoping
> = () => (_next) => (_action) => {
  if (logout.match(_action)) {
    TokenService.deleteLocalRefreshToken();
    TokenService.deleteLocalAccessToken();
    UserService.deleteLocalUser();
  }
  return _next(_action);
};
