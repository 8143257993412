export interface CustomTimePickerProps {
  step: number;
  onChange: (hours: number, minutes: number) => void;
  initialTime?: Date;
}

export enum TimePeriod {
  PM = 'PM',
  AM = 'AM',
}

export const AM_PM_HOURS_NUMBER = 12;
export const HOURS_NUMBER = 24;
const MINUTES_IN_HOUR_NUMBER = 60;

export const HOURS_ARRAY = Array.from({ length: AM_PM_HOURS_NUMBER }, (_, i) =>
  i + 1 < 10 ? `0${i + 1}` : `${i + 1}`,
);
export const MINUTES_ARRAY = Array.from({ length: MINUTES_IN_HOUR_NUMBER }, (_, i) =>
  i < 10 ? `0${i}` : i.toString(),
);
