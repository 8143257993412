/* eslint-disable import/no-import-module-exports */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';
import './i18n';
import './index.css';
import { initHotjar } from 'utils/initHotjar';
import ToasterProvider from 'Providers/ToasterProvider';
import MaterialProvider from 'Providers/MaterialProvider';
import ConfirmProvider from 'Providers/ConfirmProvider';
import * as Sentry from '@sentry/react';
import routes from './routes';
import ErrorBoundary from './ErrorBoudary';

const isDevelopment = process.env.NODE_ENV === 'development';
const hotjarId = process.env.REACT_APP_HOTJAR_ID;
const sentryDSN = process.env.REACT_APP_SENTRY_DSN;

if (!isDevelopment && hotjarId) initHotjar(process.env.REACT_APP_HOTJAR_ID);
if (!isDevelopment && sentryDSN) Sentry.init({ dsn: sentryDSN });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MaterialProvider>
        <ToasterProvider>
          <ConfirmProvider>
            <ErrorBoundary>
              <BrowserRouter children={routes} />
            </ErrorBoundary>
          </ConfirmProvider>
        </ToasterProvider>
      </MaterialProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
