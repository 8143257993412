/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface CustomToolbarProps extends React.PropsWithChildren<any> {}

export type ToolbarFormFields = {
  yearIds: number[];
};

export enum CalendarAction {
  CURRENT = 'CURRENT',
  PREV = 'PREV',
  NEXT = 'NEXT',
  TODAY = 'TODAY',
}

export type FilterByYearFields = {
  id: number;
  year: string;
};
