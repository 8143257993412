const ACCESS_TOKEN = 'admin-refresh-token';
const REFRESH_TOKEN = 'admin-access-token';

const getLocalRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);

const getLocalAccessToken = () => localStorage.getItem(ACCESS_TOKEN);

const updateLocalAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

const updateLocalRefreshToken = (token: string) => {
  localStorage.setItem(REFRESH_TOKEN, token);
};

const deleteLocalAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
};

const deleteLocalRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN);
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  updateLocalRefreshToken,
  deleteLocalRefreshToken,
  deleteLocalAccessToken,
};

export default TokenService;
