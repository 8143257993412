// TODO refactor colors

export default {
  black: '#000000',
  blue: '#3C9BDF',
  green10: '#397D49',
  green: '#5DB27E',
  grey10: '#f7f8fa',
  grey15: '#efefef',
  grey20: '#e9eaec', /// '#e5e5e5',
  grey30: '#e0e0e0',
  grey50: '#A0A5AB',
  grey40: '#cdd0d4',
  grey60: '#afb4ba',
  grey80: '#595d62',
  grey90: '#626262',
  iron: '#CDD0D4',
  transparent: 'transparent',
  white: '#ffffff',
  yellow: '#EFBF4B',
  red: '#d32f2f',
  green100: '#DEF7EC',
  green800: '#03543F',
  blue100: '#E1EFFE',
  blue800: '#1E429F',
  yellow100: '#FDF6B2',
  yellow800: '#723B13'
};
