import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgNavSchedule, SvgNavUsers } from 'svg';

import { User } from 'store/users/users.type';
import { isUserAdmin, isUserManager } from 'shared/user';
import { NavigationMenuItem } from './types';
import { getAdminNavigationItems, getManagerNavigationItems } from './utils';

export const useNavigationMenuItems = (user: User): NavigationMenuItem[] => {
  const { t } = useTranslation();
  const userIsAdmin = isUserAdmin(user);
  const userIsManager = isUserManager(user);

  return useMemo(() => {
    if (userIsAdmin) {
      return getAdminNavigationItems(t);
    }

    if (userIsManager) {
      return getManagerNavigationItems(t);
    }

    return [
      {
        Icon: SvgNavSchedule,
        id: 'SCHEDULE',
        link: '/schedule',
        primary: t('shared.inputs.navigation.schedule'),
        type: 'link',
      },
      {
        Icon: SvgNavUsers,
        id: 'MYAVAILABLITY',
        link: '/myavailability',
        primary: t('shared.inputs.navigation.myavailability'),
        type: 'link',
      },
    ];
  }, [t, userIsAdmin, userIsManager]);
};
