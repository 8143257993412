import { CustomCalendarDayProps } from 'UI/CustomCalendarDay/CustomCalendarDay.types';

export interface DatePickerProps {
  customCalendarDay: ({
    chosenDate,
    date,
    recurrenceNumber,
    setDate,
    repeatMode,
    isReadonly,
    customSelectedWeekdays,
    repeatUntilDate,
  }: CustomCalendarDayProps) => JSX.Element;
  setDate: (date: Date | null) => void;
  chosenDate: Date | null;
  repeatMode?: RepeatDateMode;
  isReadonly?: boolean;
  className?: string;
  recurrenceNumber?: number | null;
  repeatUntilDate?: Date | null;
  customSelectedWeekdays?: number[];
}

export enum RepeatDateMode {
  OneTime,
  Daily,
  Weekly,
  Monthly,
  Custom,
}
