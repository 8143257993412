import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { RootState } from 'store';
import * as endpoints from 'store/apiUtils/endpoints';

const baseQuery = fetchBaseQuery({
  baseUrl: endpoints.backendUrl,
  prepareHeaders: (headers, { extra, getState }) => {
    if ((extra as { useRefreshTokenInHeaders: boolean | undefined })?.useRefreshTokenInHeaders) {
      const token = (getState() as RootState).auth.refreshToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    }
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.accessToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export default baseQuery;
