import { createTheme, ThemeOptions } from '@mui/material';
import colors from 'styles/colors';

const options = (): ThemeOptions => ({
  palette: {
    mode: 'light',
    primary: {
      main: '#000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#AFB4BA',
      contrastText: '#fff',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: colors.grey15,
        },
        root: {
          backgroundColor: colors.grey15,
          boxShadow: `0px 1px 1px 0px ${colors.grey40}`,
        },
      },
      defaultProps: {
        elevation: 1,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorRight: {
          backgroundColor: colors.white,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          gap: '16px',
          maxWidth: '600px',
          overflow: 'hidden auto',
          padding: '24px 0',
          width: '100%',
          '@media (max-width: 768px)': {
            padding: '12px 0',
            maxWidth: '100%',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
          },
          ':focus': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
        color: 'primary',
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
        color: 'primary',
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '64px',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Outfit',
    button: {
      textTransform: 'none',
      fontWeight: 500,
      fontSize: '16px',
    },
  },
});

export const mainTheme = createTheme(options());
