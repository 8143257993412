import * as Yup from 'yup';
import { ValidationRules } from '../../shared/validationRules';
import { generateTestIdByDomain } from '../../shared/generateTestIdByDomain';

const generateTestId = generateTestIdByDomain('reset-password-form');

export const TEST_IDS = {
  passwordInput: generateTestId.input('password'),
  repeatPasswordInput: generateTestId.input('repeat-password'),
  sendPasswordButton: generateTestId.button('send-password'),
  backToLoginButton: generateTestId.button('back-to-login'),
};

export const resetPasswordValidationSchema = Yup.object({
  password: ValidationRules.password.max(250),
  // TODO use i18n version of message
  confirmPassword: ValidationRules.password.oneOf([Yup.ref('password'), null], "Passwords don't match").max(250),
});

export const TOKEN_SEARCH_NAME = 'token';
