import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import TokenService from 'services/token.service';
import * as endpoints from 'store/apiUtils/endpoints';
import { LoginResponse } from 'store/auth/auth.types';
import { logout, refreshTokens } from '../auth/auth.actions';
import baseQuery from './baseQuery';
import { DefinitionExtraOptions } from './apiUtils.types';

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  DefinitionExtraOptions
> = async (args, api, extraOptions) => {
  const reAuthenticate = async (modifiedArgs: string | FetchArgs) => {
    let result = await baseQuery(modifiedArgs, api, extraOptions);
    if (result.error && result.error.status === 401) {
      // try to get a new token
      const refreshResult = await baseQuery(
        { url: endpoints.refreshToken },
        { ...api, extra: { useRefreshTokenInHeaders: true } },
        extraOptions,
      );
      if (refreshResult.data) {
        const response = refreshResult.data as LoginResponse;
        // store the new token
        api.dispatch(refreshTokens(response));
        TokenService.updateLocalAccessToken(response.accessToken);
        TokenService.updateLocalRefreshToken(response.refreshToken);
        // retry the initial query
        result = await baseQuery(modifiedArgs, api, extraOptions);
      } else {
        api.dispatch(logout());
      }
    }
    return result;
  };

  return reAuthenticate(args);
};

export default baseQueryWithReauth;
