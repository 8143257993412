import { FormikProvider, useFormik } from 'formik';
import useTranslation from 'shared/hooks/useTranslation';
import { Button } from 'UI';
import { registerFormikTextField } from 'shared/getters/getFormikProps';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { useLoginResetPassword } from 'Pages/LoginPage/hooks';
import { LoginFormResetPasswordProps } from './types';
import * as S from './styles';
import { loginResetPasswordValidationSchema, PLACEHOLDER_EMAIL, TEST_IDS } from '../../constants';
import { LoginFormResetPasswordFields } from '../../types';
import ButtonContainer from '../../atoms/ButtonContainer';
import LoginTextField from '../../atoms/LoginTextField';

const LoginFormResetPassword = ({ onBackToLoginClick }: LoginFormResetPasswordProps): JSX.Element => {
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [resetPassword, isLoading] = useLoginResetPassword(() => setIsEmailSent(true));

  const [translations] = useTranslation([
    'page.login.back.to.login',
    'page.login.username',
    'common.input.btn.submit',
    'page.login.password.reset.success.subtitle',
    'page.login.password.reset.success.title',
    'page.login.password.reset.subtitle',
    'page.login.password.reset.title',
  ] as const);

  const formik = useFormik<LoginFormResetPasswordFields>({
    initialValues: { email: '' },
    validationSchema: loginResetPasswordValidationSchema,
    onSubmit: resetPassword,
  });

  if (isEmailSent) {
    return (
      <FormikProvider value={formik}>
        <S.Wrapper>
          <S.ResetPasswordFieldsContainer>
            <S.ResetPasswordTitle title={translations.pageLoginPasswordResetSuccessTitle} />
            <Typography fontSize={12}>{translations.pageLoginPasswordResetSuccessSubtitle}</Typography>
            <Button
              data-test-id={TEST_IDS.backToLoginButton}
              loading={isLoading}
              variant="contained"
              color="primary"
              fullWidth
              onClick={onBackToLoginClick}
            >
              {translations.pageLoginBackToLogin}
            </Button>
          </S.ResetPasswordFieldsContainer>
        </S.Wrapper>
      </FormikProvider>
    );
  }

  return (
    <FormikProvider value={formik}>
      <S.Wrapper>
        <S.ResetPasswordFieldsContainer>
          <S.ResetPasswordTitle title={translations.pageLoginPasswordResetTitle} />
          <Typography fontSize={12}>{translations.pageLoginPasswordResetSubtitle}</Typography>
          <LoginTextField
            disabled={isLoading}
            data-test-id={TEST_IDS.emailInput}
            label={translations.pageLoginUsername}
            placeholder={PLACEHOLDER_EMAIL}
            {...registerFormikTextField('email', formik)}
          />
        </S.ResetPasswordFieldsContainer>
        <ButtonContainer>
          <Button
            color="primary"
            data-test-id={TEST_IDS.sendEmailButton}
            fullWidth
            loading={isLoading}
            type="submit"
            variant="contained"
          >
            {translations.commonInputBtnSubmit}
          </Button>
          <Button variant="text" onClick={onBackToLoginClick}>
            {translations.pageLoginBackToLogin}
          </Button>
        </ButtonContainer>
      </S.Wrapper>
    </FormikProvider>
  );
};

export default LoginFormResetPassword;
