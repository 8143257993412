import styled from '@emotion/styled';
import colors from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  align-items: flex-start;
`;

export const Title = styled.div`
  color: black;
  display: flex;
  font-family: 'Outfit';
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  width: 100%;
`;

export const Subtitle = styled.div`
  color: ${colors.grey80};
  display: flex;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;
