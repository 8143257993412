import { useEffect, useState } from 'react';

export enum RESOLUTIONS {
  TABLET_RESOLUTION = 1280,
  FRAME_RESOLUTION = 960,
  MOBILE_RESOLUTION = 768,
}

/**
 *
 * @param breakpoint key identifier of a breakpoint which is exactly the same value as SCSS one.
 * @returns true if given breakpoint matches window size. It's following mobile first approach and will return true if window size is above breakpoint.
 */
export const useMediaQuery = (breakpoint: RESOLUTIONS) => {
  const query = `(max-width: ${breakpoint}px)`;
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    const handleChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    if (matchMedia.addEventListener) {
      matchMedia.addEventListener('change', handleChange);

      return () => matchMedia.removeEventListener('change', handleChange);
    }
  }, [query]);

  return matches;
};
