import styled from '@emotion/styled';
import { colors } from 'styles';
import { Button } from 'UI';

export const boxStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
};

export const CloseButton = styled(Button)`
  padding-right: 0px;
  display: flex;
  min-width: 22px;
  height: 22px;
`;

export const UpperBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const LowBlock = styled.div`
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-end;
`;

export const LinkBlock = styled.div`
  border: 1px solid ${colors.grey10};
  border-radius: 4px;
  max-width: 300px;
  overflow: hidden;
  padding: 5px 5px 0px 5px;
`;

export const CopyButton = styled(Button)`
  min-width: auto;
  width: auto;
`;
