import { User } from '../store/users/users.type';

const getLocalUser = () => {
  const user = localStorage.getItem('user');
  return user && JSON.parse(user);
};

const updateLocalUser = (user: User) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const deleteLocalUser = () => {
  localStorage.removeItem('user');
};

const UserService = {
  getLocalUser,
  updateLocalUser,
  deleteLocalUser,
};

export default UserService;
