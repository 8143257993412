import { OutlinedInput, CircularProgress } from '@mui/material';
import React from 'react';
import { CUSTOM_FORM_LABEL, CUSTOM_LABEL } from 'shared/constants';
import { SelectProps } from './Select.types';
import * as S from './Select.styles';
import InputLabel from '../InputLabel';
import { ITEM_WIDTH, MenuProps } from './Select.constants';

// TODO:  If Select have 'small' size then Label not aligned vertically,
//  the same problem can be found in MaterialUI docs examples
const Select = ({
  className,
  menuWidth,
  label,
  loading = false,
  formLabel,
  helperText,
  ...rest
}: SelectProps): JSX.Element => (
  <S.Container className={className}>
    {formLabel ? <S.MarginOffTitle className={CUSTOM_FORM_LABEL}>{formLabel}</S.MarginOffTitle> : null}

    {loading ? (
      <S.CircularProgressWrapper>
        <CircularProgress size={24} />
      </S.CircularProgressWrapper>
    ) : (
      <S.FormControl error={rest.error}>
        <InputLabel className={rest.size === 'small' ? CUSTOM_LABEL : ''}>{label}</InputLabel>
        <S.Select
          className={className}
          label={label}
          {...rest}
          MenuProps={{
            ...MenuProps,
            PaperProps: {
              ...MenuProps.PaperProps,
              style: { ...MenuProps.PaperProps.style, width: menuWidth || ITEM_WIDTH },
            },
          }}
          input={<OutlinedInput />}
        >
          {rest.children}
        </S.Select>
      </S.FormControl>
    )}
    {helperText && (
      <S.SelectHelperText required={rest.error} error={rest.error}>
        {helperText}
      </S.SelectHelperText>
    )}
  </S.Container>
);

export default Select;
