import { Form } from 'formik';
import styled from '@emotion/styled';
import { Link } from '@mui/material';

export const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 400px;
  padding: 0 16px;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const ButtonLink = styled(Link)`
  align-self: flex-end;
  cursor: pointer;
`;
