import styled from '@emotion/styled';
import { Select } from '@mui/material';
import colors from 'styles/colors';

export const SelectYear = styled(Select)`
  margin-left: 10px;
  padding: 0px;
  max-height: 40px;
  background-color: ${colors.grey10};
`;

export const Placeholder = styled.em`
  font-style: normal;
  color: ${colors.grey80};
  font-weight: 500;
`;
