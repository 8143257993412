import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import toast from 'react-hot-toast';
import useTranslation from 'shared/hooks/useTranslation';
import Modal from '@mui/material/Modal';
import { SvgXSmall } from 'svg';
import * as S from './Modal.styles';
import { ModalWithLinksProps } from './Modal.types';

const ModalWithLinks = ({ modalStatus, handleClose, link, hideLink }: ModalWithLinksProps) => {
  const [translations] = useTranslation([
    'common.form.successful',
    'common.form.unsuccessful',
    'modal.link.copy',
    'modal.link.copyiframe',
    'modal.link.share',
  ] as const);

  const writeText = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => toast.success(translations.commonFormSuccessful))
      .catch(() => toast.error(translations.commonFormUnsuccessful));
  };

  const handleCopyLink = () => {
    writeText(link);
  };

  const handleCopyLinkWithIFrame = () => {
    writeText(`<iframe src="${link}" width="960" height="584" />`);
  };

  return (
    <Modal
      open={modalStatus}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={S.boxStyle}>
        <S.UpperBlock>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {translations.modalLinkShare}
          </Typography>
          <S.CloseButton onClick={handleClose} startIcon={<SvgXSmall />} />
        </S.UpperBlock>
        <S.LowBlock>
          {!hideLink && <S.LinkBlock>{link}</S.LinkBlock>}
          <S.CopyButton onClick={handleCopyLink} variant="contained" color={'primary'}>
            {translations.modalLinkCopy}
          </S.CopyButton>
          <S.CopyButton onClick={handleCopyLinkWithIFrame} variant="contained" color={'primary'} sx={{ ml: '10px' }}>
            {translations.modalLinkCopyiframe}
          </S.CopyButton>
        </S.LowBlock>
      </Box>
    </Modal>
  );
};

export default ModalWithLinks;
