import camelCase from 'camelcase';
import { TFunction, useTranslation as useTranslationBasic } from 'react-i18next';
import * as Types from './useTranslation.types';

const useTranslation = <T extends readonly string[]>(
  key: T,
): [Types.Messages<T>, TFunction<'translation', undefined>] => {
  const { t } = useTranslationBasic();

  const translation = Object.fromEntries(
    key.map((translationKey) => [camelCase(translationKey), t(translationKey)]),
  ) as Types.Messages<T>;

  return [translation, t];
};
export default useTranslation;
