// Todo: delete here.
const Colors = {
  grey10: '#f7f8fa',
  grey15: '#efefef',
  grey20: '#e9eaec', // '#e5e5e5',
  grey40: '#cdd0d4',
  grey60: '#afb4ba',
  grey70: '#8a8a8a',
  grey80: '#595d62',
  white: '#ffffff',
  black: '#000000',
  error100: '#d32f2f',
  error50: '#f8d7d7',
  primary: '#595d62',
  transparent: 'transparent',
  darkOpacity50: 'rgba(0, 0, 0, 0.5)',
};

export const ERROR_BORDER_COLOR = {
  '&.Mui-error > .MuiSelect-select > .CustomInputPlaceholder': {
    color: Colors.grey80,
  },

  '& > .Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: Colors.black,
  },

  '.CustomInputPlaceholder': {
    color: Colors.grey80,
  },
  '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: Colors.black,
  },
};

export default Colors;
