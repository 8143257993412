import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { api } from './apiUtils/api';
import { logoutMiddleware } from './apiUtils/logoutMiddleware';
import authReducer from './auth/auth.slice';
import addonReducer from './addons/slice';
import appointmentGroupReducer from './appointmentGroups/slice';
import { logout } from './auth/auth.actions';

const appReducer = combineReducers({
  addonReducer,
  appointmentGroupReducer,
  auth: authReducer,
  [api.reducerPath]: api.reducer,
});

export const store = configureStore({
  reducer: (state, action) => {
    if (logout.match(action)) {
      state = undefined;
    }

    return appReducer(state, action);
  },
  devTools: true,
  // eslint-disable-next-line unicorn/prefer-spread
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, logoutMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
