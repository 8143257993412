import styled from '@emotion/styled';
import { FormHelperText } from '@mui/material';
import { TextField as TextInput } from '../index';

export const Container = styled.div``;
export const FullWidthTextField = styled(TextInput)`
  width: 152px;
`;

export const SelectHelperText = styled(FormHelperText)`
  margin: 4px 14px 0;
`;
