import useTranslation from 'shared/hooks/useTranslation';
import { Button } from 'UI';
import { FormikProvider, useFormik } from 'formik';
import { registerFormikTextField } from 'shared/getters/getFormikProps';
import * as S from './styles';
import FieldsContainer from '../../atoms/FieldsContainer';
import ButtonContainer from '../../atoms/ButtonContainer';
import LoginTextField from '../../atoms/LoginTextField';
import { LoginFormAuthFields } from '../../types';
import { LoginFormAuthProps } from './types';
import { loginAuthValidationSchema, PLACEHOLDER_EMAIL, PLACEHOLDER_PASSWORD, TEST_IDS } from '../../constants';
import { useLoginAuth } from '../../hooks';

const LoginFormAuth = ({ onForgotPasswordClick }: LoginFormAuthProps): JSX.Element => {
  const [login, isLoading] = useLoginAuth();
  const [translations] = useTranslation([
    'page.login.sign.in',
    'page.login.password.forgot',
    'page.login.username',
    'page.login.password',
  ] as const);

  const formik = useFormik<LoginFormAuthFields>({
    initialValues: { email: '', password: '' },
    validationSchema: loginAuthValidationSchema,
    onSubmit: login,
  });

  return (
    <FormikProvider value={formik}>
      <S.Wrapper>
        <FieldsContainer>
          <LoginTextField
            data-test-id={TEST_IDS.emailInput}
            disabled={isLoading}
            fullWidth
            label={translations.pageLoginUsername}
            placeholder={PLACEHOLDER_EMAIL}
            variant="filled"
            {...registerFormikTextField('email', formik)}
          />
          <LoginTextField
            data-test-id={TEST_IDS.passwordInput}
            disabled={isLoading}
            fullWidth
            label={translations.pageLoginPassword}
            placeholder={PLACEHOLDER_PASSWORD}
            type="password"
            variant="filled"
            {...registerFormikTextField('password', formik)}
          />
          <S.ButtonLink variant="body2" onClick={onForgotPasswordClick}>
            {translations.pageLoginPasswordForgot}
          </S.ButtonLink>
        </FieldsContainer>
        <ButtonContainer>
          <Button
            color="primary"
            data-test-id={TEST_IDS.signinButton}
            fullWidth
            loading={isLoading}
            variant="contained"
            type="submit"
          >
            {translations.pageLoginSignIn}
          </Button>
        </ButtonContainer>
      </S.Wrapper>
    </FormikProvider>
  );
};

export default LoginFormAuth;
