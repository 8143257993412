import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { RoutesEnum } from 'routes';
import useTranslation from 'shared/hooks/useTranslation';
import { useLoginMutation, useSendResetPasswordMutation } from 'store/auth/auth.api';
import { useNavigate } from 'react-router-dom';
import { LoginFormAuthFields, LoginFormResetPasswordFields } from './types';

export const useLoginAuth = () => {
  const [translations] = useTranslation(['login.form.sign.in.error'] as const);
  const [login, { isLoading }] = useLoginMutation();

  const navigate = useNavigate();
  const loginSubmit = useCallback(
    async (values: LoginFormAuthFields) => {
      try {
        await login(values).unwrap();
        navigate(RoutesEnum.root);
      } catch {
        toast.error(translations.loginFormSignInError);
      }
    },
    [login, navigate, translations.loginFormSignInError],
  );

  return [loginSubmit, isLoading] as const;
};

export const useLoginResetPassword = (onLoginResetPasswordCallback: () => void) => {
  const [translations] = useTranslation(['common.error.submit'] as const);
  const [requestResetPassword, { isLoading }] = useSendResetPasswordMutation();

  const resetPassword = useCallback(
    async (values: LoginFormResetPasswordFields) => {
      try {
        requestResetPassword(values).unwrap();
        onLoginResetPasswordCallback();
      } catch {
        toast.error(translations.commonErrorSubmit);
      }
    },
    [onLoginResetPasswordCallback, requestResetPassword, translations.commonErrorSubmit],
  );

  return [resetPassword, isLoading] as const;
};
