import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { SvgElevatedLogo } from 'svg';
import * as S from './styles';
import { RoutesEnum } from '../../routes';
import { HeaderProps } from './types';

const Header = ({ onSidebarOpen }: HeaderProps) => {
  const navigate = useNavigate();

  const onLogoClick = () => {
    navigate(RoutesEnum.root);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <S.IconButtonMenu onClick={onSidebarOpen}>
          <MenuIcon fontSize="small" />
        </S.IconButtonMenu>
        <S.IconButton onClick={onLogoClick}>
          <SvgElevatedLogo />
        </S.IconButton>
      </S.Content>
    </S.Wrapper>
  );
};

export default Header;
