import styled from '@emotion/styled';
import { colors } from '../../styles';

export const Container = styled.span`
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: ${colors.grey60};
`;
