import { Availability } from 'Domains/MyAvailabilityTable/MyAvailabilityTable.types';
import { RecurrenceType } from 'store/leads/leads.types';
import { UserType } from '../../Domains/UserForm/UserForm.types';

export enum UserRole {
  ADMIN = 'admin',
  MANAGER = 'manager',
  WORKER = 'worker',
}

export enum BillingType {
  PerService = 'PerService',
  PerHour = 'PerHour',
}

export interface User {
  availability: Availability[];
  billingType?: BillingType;
  buildingIds: number[];
  email: string;
  firstName: string;
  id: number;
  industryIds: number[];
  lastName: string;
  phone?: string;
  rate?: number;
  role: UserRole;
  timezone?: string;
  type: UserType;
}

export interface Manager extends User {
  timezone: string;
}

export interface Worker extends User {
  billingType: BillingType;
  rate: number;
  type: UserType;
}

export interface CreateUserBody {
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  industryIds: number[];
  buildingIds: number[];
  billingType?: BillingType;
  timezone?: string;
  rate?: number;
}

export interface UpdateUserBody {
  id: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  role?: UserRole;
  industryIds?: number[];
  buildingIds?: number[];
  billingType?: BillingType;
  timezone?: string;
  rate?: number;
}

export interface GetUsersParams {
  role?: UserRole;
}

export interface ResetPasswordBody {
  token: string;
  password: string;
}

export interface GetWorkersAvailabilityParams {
  bookingId?: number;
  buildingId?: number;
  endDate: number;
  industryId: number;
  recurrenceType?: RecurrenceType;
  recurrenceEndDate?: number;
  startDate: number;
}

export interface GetWorkersScheduleParams {
  startDate: number;
  endDate: number;
  industryId: number;
  buildingId: number;
}
