import * as endpoints from 'store/apiUtils/endpoints';
import providesListTag from 'store/apiUtils/providesListTag';
import { api } from '../apiUtils/api';
import { Addon, CreateAddonBody, UpdateAddonArgs } from './type';

export const addonsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAddons: builder.query<Addon[], void>({
      query: () => endpoints.addons,
      providesTags: (result) => providesListTag(result, 'Addon'),
    }),
    getAddonById: builder.query<Addon, { id: number }>({
      query: ({ id }) => `${endpoints.addons}/${id}`,
      providesTags: (result) => [{ type: 'Addon', id: result?.id }],
    }),
    createAddon: builder.mutation<Addon, CreateAddonBody>({
      query: (body) => ({
        url: endpoints.addons,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        { type: 'Addon', id: 'LIST' },
        { type: 'Industry', id: 'LIST' },
      ],
    }),
    updateAddon: builder.mutation<Addon, UpdateAddonArgs>({
      query: ({ id, ...rest }) => ({
        url: `${endpoints.addons}/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: (result) => [
        { type: 'Addon', id: result?.id },
        { type: 'Industry', id: 'LIST' },
      ],
    }),
    deleteAddon: builder.mutation<number, number>({
      query: (id) => ({
        url: `${endpoints.addons}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _, id) => [
        { type: 'Addon', id },
        { type: 'Industry', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetAddonsQuery,
  useGetAddonByIdQuery,
  useDeleteAddonMutation,
  useCreateAddonMutation,
  useUpdateAddonMutation,
  usePrefetch: useAddonsPrefetch,
} = addonsApi;
