import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import * as S from './styles';
import Header from 'Domains/Header';
import NavigationSidebar from 'Domains/NavigationSidebar';

function App() {
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  // FETCH DATA
  // TODO seems like weak code
  useEffect(() => {
    const init = async () => {
      setIsAppLoaded(true);
    };
    init();
  }, []);

  if (!isAppLoaded) {
    return null;
  }

  return (
    <>
      <Header onSidebarOpen={() => setSidebarOpen(true)} />
      <S.PageWrapper>
        <NavigationSidebar onClose={() => setSidebarOpen(false)} open={isSidebarOpen} />
        <Outlet />
      </S.PageWrapper>
    </>
  );
}

export default App;
