import { INPUT_PLACEHOLDER_CLASS, InputPlaceholderProps } from './InputPlaceholder.types';
import * as S from './InputPlaceholder.styles';

const InputPlaceholder = ({ className, text }: InputPlaceholderProps): JSX.Element => (
  <S.Container className={className ? `${className} ${INPUT_PLACEHOLDER_CLASS}` : INPUT_PLACEHOLDER_CLASS} title={text}>
    {text}
  </S.Container>
);

export default InputPlaceholder;
