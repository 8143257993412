import styled from '@emotion/styled';
import { List as MUIList, Drawer as MUIDrawer } from '@mui/material';
import colors from 'shared/colors';

export const Drawer = styled(MUIDrawer)`
  z-index: 999;

  & .MuiPaper-root {
    background-color: ${colors.grey10};
    padding: 76px 12px 12px 12px;
    width: 240px;

    @media (max-width: 768px) {
      padding: 12px;
    }
  }

  @media (max-width: 768px) {
    z-index: 9999;
  }
`;

export const List = styled(MUIList)`
  overflow: hidden auto;
  padding: 0;
`;
