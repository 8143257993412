import styled from '@emotion/styled';
import { colors } from 'styles';
import { NavLink as RouterNavLink } from 'react-router-dom';

export const NavLink = styled(RouterNavLink)`
  align-items: center;
  background-color: ${colors.grey10};
  border-radius: 8px;
  color: ${colors.black};
  display: flex;
  height: 44px;
  overflow: hidden;
  padding: 8px 12px;
  text-decoration: none;
  user-select: none;
  width: 100%;

  &:hover {
    background-color: ${colors.grey20};
  }

  &.active {
    background-color: ${colors.black};
    color: ${colors.white};
  }
`;
