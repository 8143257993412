import { generateTestIdByDomain } from 'shared/generateTestIdByDomain';
import * as Yup from 'yup';
import { ValidationRules } from 'shared/validationRules';

const generateTestId = generateTestIdByDomain('toolbar-form');

export const TEST_IDS = {
  toolbarYearSelect: generateTestId.select('year'),
};

export const userValidationSchema = Yup.object({
  yearIds: ValidationRules.requiredArray,
});

export const LAST_AVAILABLE_YEAR = 2032;
