import { ConfirmOptions } from 'material-ui-confirm';
import { fonts, colors } from 'styles';

export const POPUP_DEFAULT_STYLES: ConfirmOptions = {
  confirmationButtonProps: {
    variant: 'contained',
  },
  cancellationButtonProps: { variant: 'outlined' },
  contentProps: {
    sx: {
      alignItems: 'center',
      color: colors.grey80,
      display: 'flex',
      fontFamily: fonts.family.outfit,
      fontSize: '16px',
      fontWeight: '500',
      letterSpacing: ' -0.02em',
      lineHeight: '20px',
      padding: 0,
      paddingBottom: '48px',
    },
  },
  titleProps: {
    sx: {
      color: colors.black,
      fontFamily: fonts.family.outfit,
      fontSize: '20px',
      fontWeight: 600,
      letterSpacing: '-0.02em',
      lineHeight: '25px',
      padding: 0,
      paddingBottom: '24px',
    },
  },
  dialogProps: {
    PaperProps: {
      sx: {
        '& > .MuiDialogActions-root': {
          padding: 0,
          '& > button': {
            borderRadius: '4px',
            fontFamily: fonts.family.outfit,
            fontWeight: '500',
            letterSpacing: '-0.02em',
            lineHeight: '18px',
            padding: '10px 32px',
          },
        },
        padding: '24px',
        width: '493px',
      },
    },
  },
};
