import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { fonts } from 'styles';

export const Container = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  font-family: ${fonts.family.outfit};
  margin-bottom: 8px;
`;
