import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
  padding-top: 64px;
  padding-left: 240px;
  overflow: hidden;

  @media (max-width: 768px) {
    padding-left: 0;
  }
`;
