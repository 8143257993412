import { css } from 'styled-components';

const buttonDefault = css`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const buttonXMargin = css`
  margin: 0px 10px 0 10px;
`;

const buttonYMargin = css`
  margin: 10px 0px 10px 0px;
`;

const buttonXYMargin12 = css`
  margin: 12px;
`;

const buttonLeftMargin12 = css`
  margin-left: 12px;
`;

const buttonRightMargin12 = css`
  margin-right: 12px;
`;

const buttonTopMargin12 = css`
  margin-top: 12px;
`;

const buttonBottomMargin12 = css`
  margin-bottom: 12px;
`;

const buttonMargin6 = css`
  margin: 6px;
`;

// TODO: refractor ewith digit
export default {
  buttonDefault,
  buttonXMargin,
  buttonYMargin,
  buttonMargin6,
  buttonXYMargin12,
  buttonLeftMargin12,
  buttonRightMargin12,
  buttonTopMargin12,
  buttonBottomMargin12,
};
