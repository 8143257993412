import { Outlet } from 'react-router-dom';
import S from './appointmentsMenu.styles';

function AppointmentsMenu() {
  return (
    <S.AppointmentsMenuContainer>
      <Outlet />
    </S.AppointmentsMenuContainer>
  );
}
export default AppointmentsMenu;
