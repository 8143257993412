import { FormikContextType } from 'formik';

export const registerFormikTextField = <T extends { [key: string]: unknown }>(
  name: string,
  formik: FormikContextType<T>,
) => ({
  id: name,
  name,
  value: formik.values[name],
  onChange: formik.handleChange,
  onBlur: formik.handleBlur,
  error: formik.touched[name] && Boolean(formik.errors[name]),
  helperText: (formik.touched[name] && formik.errors[name]) || ' ',
});
