import styled from '@emotion/styled';
import { AppBar, Toolbar, IconButton as MuiIconButton } from '@mui/material';
import colors from 'shared/colors';

export const Wrapper = styled(AppBar)``;
export const Content = styled(Toolbar)``;

export const Logo = styled.img`
  max-height: 100%;
  padding: 0 16px;
  text-align: center;
  vertical-align: baseline;
`;

export const IconButton = styled(MuiIconButton)`
  color: ${colors.black};
  padding: 16px 8px;
`;

export const IconButtonMenu = styled(IconButton)`
  display: none;
  padding: 8px;

  @media (max-width: 768px) {
    max-height: 100%;
    display: inline-flex;
  }
`;
