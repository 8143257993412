import { useSelector } from 'react-redux';
import { RESOLUTIONS, useMediaQuery } from 'shared/useMediaQuery';
import { selectUser } from 'store/auth/auth.selectors';
import NavigationItemButton from './components/NavigationItemButton';
import NavigationItemLink from './components/NavigationItemLink';
import NavigationProfile from './components/NavigationProfile';
import { useNavigationMenuItems } from './hooks';
import Logout from './molecules/Logout';
import * as S from './styles';
import { NavigationSidebarProps } from './types';

const NavigationSidebar = ({ open, onClose }: NavigationSidebarProps) => {
  const isMobile = useMediaQuery(RESOLUTIONS.MOBILE_RESOLUTION);
  const user = useSelector(selectUser);

  const navigationItems = useNavigationMenuItems(user);

  const renderNavigation = () => (
    <>
      <S.List>
        <NavigationProfile email={user?.email} role={user?.role} />
        {navigationItems.map((navigationItem) =>
          navigationItem.type === 'button' ? (
            <NavigationItemButton key={navigationItem.id} {...navigationItem} />
          ) : (
            <NavigationItemLink key={navigationItem.id} {...navigationItem} />
          ),
        )}
      </S.List>
      <Logout />
    </>
  );

  if (isMobile) {
    return (
      <S.Drawer anchor="left" onClose={onClose} open={open} variant="temporary">
        {renderNavigation()}
      </S.Drawer>
    );
  }

  return (
    <S.Drawer anchor="left" open variant="permanent">
      {renderNavigation()}
    </S.Drawer>
  );
};

export default NavigationSidebar;
