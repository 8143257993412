import { DateRangePicker as MuiDateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateRangePickerProps } from './DateRangePicker.types';

const DateRangePicker = (props: DateRangePickerProps): JSX.Element => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <MuiDateRangePicker {...props} />
  </LocalizationProvider>
);

export default DateRangePicker;
