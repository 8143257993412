import styled from '@emotion/styled';
import { Accordion } from '@mui/material';
import { colors } from '../../styles';

export const Wrapper = styled(Accordion)`
  border-radius: 8px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${colors.grey60};

  .MuiAccordionSummary-root {
    min-height: 40px;
  }

  .MuiAccordionSummary-content {
    margin: 8px 0;
  }

  &&& {
    padding: 0;
    width: 100%;
  }

  &:hover {
    border: 1px solid ${colors.black};
  }
`;
