import styled from '@emotion/styled';
import Select from '@mui/material/Select';
import ScheduleYearSelect from 'UI/SelectByYear';
import colors from 'shared/colors';

export const Header = styled.div`
  display: flex;
`;

export const CenterWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: 40px;
  justify-content: center;
  margin-bottom: 0px;
`;

export const Container = styled(Select)`
  width: 100%;
  flex: 0;
`;

export const YearSelector = styled(ScheduleYearSelect)`
  max-height: 24px;
  background-color: ${colors.grey10};
  margin-top: 8px;
  margin-right: 10px;
`;
