import React from 'react';
import { ListItemText, MenuItem, SelectChangeEvent } from '@mui/material';
import { generateTestIdByDomain } from 'shared/generateTestIdByDomain';
import * as S from './SelectByYear.styles';
import { FilterByYearSelectProps } from './SelectByYear.types';

const TEST_ID_YEAR = generateTestIdByDomain('schedule-dropdowns-form').select('year');

const ScheduleYearSelect = ({ selectedYear, onYearChange, listOfYears }: FilterByYearSelectProps): JSX.Element => {
  const onScheduleYearSelectChange = (event: SelectChangeEvent<number>) => {
    const {
      target: { value: nextYear },
    } = event;
    onYearChange(nextYear as number);
  };

  const renderValue = () => <S.Placeholder>{selectedYear}</S.Placeholder>;

  return (
    <S.SelectYear
      data-test={TEST_ID_YEAR}
      value={selectedYear}
      onChange={(event) => onScheduleYearSelectChange(event as SelectChangeEvent<number>)}
      renderValue={renderValue}
      size="small"
    >
      {listOfYears.map((year) => (
        <MenuItem key={year} value={year}>
          <ListItemText primary={year} />
        </MenuItem>
      ))}
    </S.SelectYear>
  );
};

export default ScheduleYearSelect;
