import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker } from '@mui/lab';
import { isValid } from 'date-fns';
import * as S from './DatePicker.styles';
import { DatePickerProps } from './DatePicker.types';
import { ERROR_BORDER_COLOR } from '../../styles/colors';

export default function DatePickerComponent({ value, ...rest }: DatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        {...rest}
        value={value}
        inputFormat="MM/dd/yyyy"
        onChange={(newValue) => (isValid(newValue) ? rest.onChange(newValue) : null)}
        renderInput={(params) => (
          <S.FullWidthTextField
            {...params}
            helperText=" "
            size="small"
            error={!value}
            sx={ERROR_BORDER_COLOR}
            variant="outlined"
          />
        )}
      />
    </LocalizationProvider>
  );
}
