export type LoginFormAuthFields = {
  email: string;
  password: string;
};

export type LoginFormResetPasswordFields = {
  email: string;
};

export enum AuthStep {
  AUTH_STEP_LOGIN,
  AUTH_STEP_RESET_PASSWORD,
}
