import styled from '@emotion/styled';
import MiuTextField from '@mui/material/TextField';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  & .MuiTextField-root {
    width: 100%;
  }
`;

export const MarginOffTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  height: 100%;
  line-height: 1.5;
  padding: 8px 0;
`;

export const TextField = styled(MiuTextField)`
  flex-grow: 1;
  width: 0;
`;
