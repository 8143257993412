import React, { KeyboardEventHandler, useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { CUSTOM_FORM_LABEL } from 'shared/constants';
import { TextFieldProps } from './TextField.types';
import * as S from './TextField.styles';
import { INPUT_DELAY } from './TextField.constants';

export const checkIfValidNumberInput: KeyboardEventHandler<HTMLDivElement> = (event) => {
  // Check if input type number is valid as input type number doesn't currently work in browsers like Safari and Firefox
  // Allowing: Integers | Backspace | Tab | Delete | Left & Right arrow keys
  const allowedCharacter = /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/;

  return !event.key.match(allowedCharacter) && event.preventDefault();
};

const MemoizedTextField = React.memo(
  ({ formLabel, className, showMaxLength, formatValue, ...rest }: TextFieldProps) => {
    const [innerValue, setInnerValue] = useState('');

    useEffect(() => {
      if (rest.value) {
        setInnerValue(rest.value as string);
      } else {
        setInnerValue('');
      }
    }, [rest.value]);

    const debouncedHandleOnChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      if (rest.onChange) {
        rest.onChange(event);
      }
    }, INPUT_DELAY);

    const handleOnChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();

        const newValue = event.currentTarget.value;
        setInnerValue(newValue);
        debouncedHandleOnChange(event);
      },
      [debouncedHandleOnChange],
    );

    // helper for formatting typed value in the TextField. Value is affected only visually
    const formatFieldValue = (value: unknown) => {
      if (formatValue) {
        return formatValue(value);
      }
      return value;
    };

    return formLabel ? (
      <S.Container className={className}>
        <S.MarginOffTitle className={CUSTOM_FORM_LABEL}>{formLabel}</S.MarginOffTitle>
        <S.TextField {...rest} value={formatFieldValue(innerValue)} onChange={handleOnChange} />
        {showMaxLength && `${innerValue.length}/250`}
      </S.Container>
    ) : (
      <S.Container className={className}>
        <S.TextField
          {...rest}
          value={formatFieldValue(innerValue)}
          onChange={handleOnChange}
          onKeyDown={rest.type === 'number' ? checkIfValidNumberInput : undefined}
        />
      </S.Container>
    );
  },
);

export default MemoizedTextField;
