import { generateTestIdByDomain } from 'shared/generateTestIdByDomain';
import { ValidationRules } from 'shared/validationRules';
import * as Yup from 'yup';

const generateTestId = generateTestIdByDomain('login-form');

export const PLACEHOLDER_EMAIL = 'example@mail.com';
export const PLACEHOLDER_PASSWORD = '"•••••••••••••"';

export const TEST_IDS = {
  emailInput: generateTestId.input('email'),
  passwordInput: generateTestId.input('password'),
  signinButton: generateTestId.button('sign-in'),
  forgotPasswordButton: generateTestId.button('forgot-password'),
  sendEmailButton: generateTestId.button('send-email'),
  backToLoginButton: generateTestId.button('back-to-login'),
  signupButton: generateTestId.button('sign-up'),
};

export const loginAuthValidationSchema = Yup.object({
  email: ValidationRules.email,
  password: ValidationRules.password.max(250),
});

export const loginResetPasswordValidationSchema = Yup.object({
  email: ValidationRules.email,
});
