import styled from '@emotion/styled';
import COLORS from 'styles/colors';

interface SelectedProps {
  isSelected?: boolean;
}

interface MonthDayProps {
  isSelected?: boolean;
}
interface DayWrapperProps {
  isPast?: boolean;
}

export const CenterWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: 40px;
  justify-content: center;
`;

export const MonthDay = styled.div<MonthDayProps>`
  display: flex;
  justify-content: flex-start;
  padding-top: 2px;
  padding-left: 5px;
  width: 100%;
  padding-bottom: auto;
  height: 100%;
  ${({ isSelected }) =>
    isSelected &&
    `border: 1px solid ${COLORS.grey80};
    padding-top: 0px;
    padding-left: 2px;`};
`;

export const DayWrapper = styled.div<DayWrapperProps>`
  position: relative;
  margin: 0px;
  height: 100%;
  ${({ isPast }) =>
    isPast &&
    `width: 100%;
    background: ${COLORS.grey20};
    color: ${COLORS.white};`};
`;

export const Selected = styled.div<SelectedProps>`
  display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
  position: absolute;
  width: 20px;
  height: 18px;
  border-bottom-left-radius: 8px;
  right: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.grey80};
`;

export const SelectedIcon = styled.img`
  width: 12px;
`;
