import { RecurrenceType } from 'store/leads/leads.types';

export interface CreateAppointmentGroupPayload {
  activeCampaignPipelineId: number | undefined;
  appointmentIds: number[];
  availableTimeRanges: TimeRanges[];
  buildingIds: number[];
  customPricingAddons?: CustomPricingAddon[];
  customPricingVariants?: CustomPricingVariant[];
  discounts: RecurrenceDiscount[];
  hasTimeRangeSelection: boolean;
  industryIds: number[];
  name: string;
  scheduleManagerId: number | undefined;
}

export interface UpdateAppointmentGroupPayload {
  activeCampaignPipelineId: number | undefined;
  appointmentIds: number[];
  availableTimeRanges: TimeRanges[];
  buildingIds: number[];
  customPricingAddons?: CustomPricingAddon[];
  customPricingVariants?: CustomPricingVariant[];
  discounts: RecurrenceDiscount[];
  hasTimeRangeSelection: boolean;
  enableAnytimeTimeRange: boolean;
  id: number;
  industryIds: number[];
  name: string;
  scheduleManagerId: number | undefined;
}

export interface CustomPricingVariant {
  price: number;
  variantId: number;
}

export interface CustomPricingAddon {
  price: number;
  addonId: number;
}

export interface AppointmentGroup {
  activeCampaignPipelineId: number | undefined;
  appointmentIds: number[];
  availableTimeRanges: TimeRange[];
  buildingIds: number[];
  createdAt?: string;
  customPricingAddons: CustomPricingAddon[];
  customPricingVariants: CustomPricingVariant[];
  discounts: RecurrenceDiscount[];
  hasTimeRangeSelection: boolean;
  enableAnytimeTimeRange: boolean;
  id: number;
  industryIds: number[];
  name: string;
  scheduleManagerId: number | undefined;
  updatedAt?: string;
}

export enum TimeRanges {
  Anytime = 'anytime',
  EarlyMorning = 'early-morning',
  Morning = 'morning',
  Midday = 'midday',
  Afternoon = 'afternoon',
  Evening = 'evening',
}

export interface TimeRange {
  key: TimeRanges;
  startTimeMin: number;
  endTimeMin: number;
}

export interface RecurrenceDiscount {
  discountPercent: number;
  recurrenceType: RecurrenceType;
}

export type AppointmentGroupReducerState = {
  appointmentGroups: AppointmentGroup[];
};
